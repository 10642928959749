<template>
  <ListingImageItem
    :libraries-list="pagesList"
    :show-counter="false"
    @navigate="navigateNext($event, 'wsPage')"
    v-on="$listeners">
    <template #actions="{ item }">
      <AppKebabActions>
        <template #activator="{ on }">
          <span
            class="sourcery__icon-wrapper white"
            v-on="on"
            @click.prevent>
            <v-icon color="black">
              mdi-dots-horizontal
            </v-icon>
          </span>
        </template>

        <template>
          <PageFollowWithWsSelection
            v-if="workspaceSelectorOnPages"
            :item="item" />
          <app-icon-btn
            v-else-if="isFollowed(item.id)"
            icon-height="15"
            show-tooltip
            bottom
            icon-name="mdi-account-multiple-minus"
            @click="onUnFollowPage(item)">
            Unfollow
          </app-icon-btn>
          <SuggestWorkspacePageDialog
            :workspace-page-id="item.id"
            :page-workspace-id="item.workspaceId"
            class="mr-2"
            is-icon
            show-in-kebab-action />
        </template>
      </AppKebabActions>
    </template>
  </ListingImageItem>
</template>
<script>
import {
  mapGetters, mapActions,
} from 'vuex';
import NavigateToCollection from '@/mixins/NavigateToCollection';
import AppKebabActions from '@/components/App/AppKebabActions/index.vue';
import ListingImageItem from '@/components/Listing/ListingImageItem';
import PageFollowWithWsSelection from '@/components/Collections/CollectionsActions/CollectionsActionsComponent/PageFollow/PageFollowWithWsSelection';
import SuggestWorkspacePageDialog from '@/components/App/AppSuggestDialog/SuggestWorkspacePageDialog';
export default {
  name: 'PagesGallery',
  components: {
    ListingImageItem,
    AppKebabActions,
    PageFollowWithWsSelection,
    SuggestWorkspacePageDialog,
  },
  mixins: [NavigateToCollection],
  props: {
    pagesList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('MyPages', ['getIdsOfMyPages']),
    ...mapGetters({
      workspaceSelectorOnPages: 'FeatureFlags/workspaceSelectorOnPages',
    }),
    ...mapGetters('Workspace', ['getActiveWorkspaceId']),
  },
  methods: {
    ...mapActions('MyPages', ['unfollowPage', 'followPage']),
    isFollowed(pageId = '') {
      return this.getIdsOfMyPages.includes(pageId);
    },
    async onUnFollowPage({ id = '', workspaceId = '' }) {
      await this.unfollowPage({
        pageId: id,
        pageWorkspaceId: workspaceId,
      });
    },
  },
};
</script>
